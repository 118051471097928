import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { sweetRecipes } from "../../data/recipes";
import { carouselImage } from "../../themes/CommonStyles";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const SweetCards = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mx: 2 }}>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {sweetRecipes.map((sweetRec, index) => {
          return (
            <Grid key={index} item xs={12} sm={4} md={4} lg={4}>
              <Box
                className="carouselCard"
                sx={{
                  flexGrow: 1,
                  position: "relative",
                }}
              >
                <Box sx={{ cursor: "pointer" }}>
                  <Box
                    component="img"
                    sx={carouselImage}
                    src={sweetRec.imgUrl ? sweetRec.imgUrl : null}
                    alt={sweetRec.title}
                    onClick={() => navigate(`/recipe/${sweetRec.nav}`)}
                  ></Box>
                </Box>
                <Box>
                  <Box sx={{ mt: 1 }}>
                    <Paper className="overflow3line">
                      <Link
                        href={`/recipe/${sweetRec.nav}`}
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {sweetRec.title}
                      </Link>
                    </Paper>
                    {/* <Box component='span' className='overflow3line' >{article.description}
                </Box> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SweetCards;
