import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { dFlex } from "../themes/CommonStyles";
import DarkMode from "./DarkMode";
import Logo from "./Logo";
import MenuIcon from "@mui/icons-material/Menu";
import SideNavbar from "./SideNavbar";

const MobileHeader = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Box sx={{ minHeight: 60 }} pt={1}>
      <Box sx={{ ...dFlex }}>
        <Box flex={1}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <SideNavbar setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
        </Box>
        <Box flex={10}>
          <Logo />
        </Box>
        <Box flex={1}>
          <DarkMode />
        </Box>

        {/* <IconButton sx={{p: '10px'}}> <SearchIcon/> </IconButton>
        <InputBase sx={{ml: 1, flex: 1}} placeholder="where to?" />
        <IconButton type='submit' sx={{p: '10px'}}> <SettingsInputComponentIcon/> </IconButton> */}
      </Box>
    </Box>
  );
};

export default MobileHeader;
