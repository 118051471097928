import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { displayOnDesktop } from "../../themes/CommonStyles";

const Login = () => {
  const [usrToken, setUsrToken] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem("usrToken", usrToken);
    navigate("/admin/article/review");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box>
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: 100,
          overflowY: "scroll",
        }}
      >
        <Box component="form" mt={2}>
          <Box>
            <label>Login Token: </label>
            <TextField
              id="outlined-basic"
              label="Login Token"
              variant="outlined"
              name="usrToken"
              required
              value={usrToken}
              onChange={(e) => setUsrToken(e.target.value)}
              sx={{ width: "70%" }}
            />
          </Box>
          <Button variant="contained" onClick={handleSubmit}>
            Login
          </Button>
        </Box>
      </Box>
      <Box sx={displayOnDesktop}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Login;
