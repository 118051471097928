import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { latestRecipes, riceRecipes, saladRecipes } from "../../data/recipes";
import { carouselImage } from "../../themes/CommonStyles";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const RiceCards = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mx: 2 }}>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {riceRecipes.map((saladRec, index) => {
          return (
            <Grid key={index} item xs={12} sm={4} md={4} lg={4}>
              <Box
                className="carouselCard"
                sx={{
                  flexGrow: 1,
                  position: "relative",
                }}
              >
                {/* <Box sx={fixedIcon}>
            <IconButton aria-label="add to favorites">
                <Checkbox  icon={<FavoriteBorder />} checkedIcon={<Favorite sx={{color:"red"}}/>} />
            </IconButton>
        </Box>  */}
                <Box sx={{ cursor: "pointer" }}>
                  <Box
                    component="img"
                    sx={carouselImage}
                    src={saladRec.imgUrl ? saladRec.imgUrl : null}
                    alt={saladRec.title}
                    onClick={() => navigate(`/recipe/${saladRec.nav}`)}
                  ></Box>
                </Box>
                <Box>
                  <Box sx={{ mt: 1 }}>
                    <Paper className="overflow2line">
                      <Link
                        href={saladRec.nav}
                        sx={{
                          fontWeight: "bold",
                        }}
                        onClick={() => navigate(`/recipe/${saladRec.nav}`)}
                      >
                        {saladRec.title}
                      </Link>
                    </Paper>
                    {/* <Box component='span' className='overflow3line' >{article.description}
                </Box> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default RiceCards;
