import ArticleIcon from "@mui/icons-material/Article";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import PsychologyIcon from "@mui/icons-material/Psychology";
import WorkIcon from "@mui/icons-material/Work";
import PublicIcon from "@mui/icons-material/Public";

export const homeMenu = [
  {
    id: 1,
    label: "All",
    icon: <ArticleIcon />,
    imgUrl: "https://images.unsplash.com/photo-1607532941433-304659e8198a",
  },
  { id: 2, label: "World", icon: <PublicIcon /> },
  //{ id: 3, label: "Entertainment", icon: <LocalMoviesIcon /> },
  { id: 3, label: "Science & Tech", icon: <PsychologyIcon /> },
  { id: 4, label: "Business", icon: <WorkIcon /> },
];
