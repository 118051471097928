import { Box, Typography } from "@mui/material";
import { pink } from "@mui/material/colors";
import React from "react";
import { flexCenter } from "../themes/CommonStyles";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";

const Logo = () => {
  return (
    <Box sx={flexCenter}>
      <DonutSmallIcon sx={{ color: pink[500], fontSize: 40 }} />
      <Typography
        sx={{
          ml: 1,
          color: (theme) => theme.palette.secondary.main,
          fontSize: "20px",
          fontWeight: "bold",
        }}
        component="h3"
      >
        7Taste
      </Typography>
    </Box>
  );
};

export default Logo;
