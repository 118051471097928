import { Box, Button, Container, Grid, styled } from "@mui/material";
import React from "react";
import { displayOnDesktop } from "../themes/CommonStyles";
import Logo from "./Logo";
import MobileHeader from "./MobileHeader";
import ProfileSettings from "./ProfileSettings";
import { useNavigate } from "react-router-dom";

const MenuButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: "larger",
  fontWeight: "bolder",
  padding: "6px 12px",
  border: "none",
  lineHeight: 1.5,
  backgroundColor: "transparent",
  borderColor: "none",
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "none",
    boxShadow: "none",
    color: "#E91E63",
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#E91E63",
  },
  "&:focus": {
    boxShadow: "none",
  },
});

const Header = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        minHeight: 60,
        borderBottom: "1px solid #ddd",
      }}
    >
      <Container maxWidth="xl" sx={displayOnDesktop}>
        <Grid container rowSpacing={3} columnSpacing={3} mb={1}>
          <Grid item sm={2} md={2} lg={2} mt={1}>
            <Logo />
          </Grid>
          <Grid item sm={2} md={2} lg={4} mt={1}>
            <Box>
              <MenuButton variant="text" onClick={() => navigate("/")}>
                Home
              </MenuButton>
              {/* <MenuButton variant="text" onClick={() => navigate("/ent")}>
                Entertainment
              </MenuButton> */}
            </Box>
          </Grid>
          <Grid
            item
            sm={2}
            md={2}
            lg={6}
            mt={1}
            justifyContent={"flex-end"}
            display="flex"
          >
            {/* <ProfileSettings /> */}
          </Grid>
          {/* <Box
          sx={{
            ...flexBetweenCenter,
            minHeight: 60,
            px: 4,
          }}
        >
          <Box sx={displayOnDesktop}>
            <Logo />
          </Box>
          <Box sx={displayOnDesktop}>
            <Button variant="outlined" sx={{ mr: "6" }}>
              News
            </Button>
            <Button variant="outlined">Trailer</Button>
          </Box>
          <Box sx={displayOnDesktop}></Box>
          <Box sx={displayOnDesktop}>
            <ProfileSettings />
          </Box>
        </Box> */}
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ display: { xs: "block", md: "none" } }}>
        <MobileHeader />
      </Container>
    </Box>
  );
};

export default Header;
