import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MobileFooter from "../../components/MobileFooter";
import { displayOnDesktop } from "../../themes/CommonStyles";
import ReactGA from "react-ga4";

const Privacy = () => {
  ReactGA.send(window.location.pathname);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>Privacy Policy | 7Taste</title>
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="7taste" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Box>
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: 100,
          overflowY: "scroll",
        }}
      >
        <Container maxWidth="xl" sx={{ mb: 3 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box mt={1} flex="1"></Box>
            <Box mt={1} flex="8">
              <Box mt={1}>
                <Typography variant="h1" fontSize={24}>
                  Privacy Policy
                </Typography>
              </Box>
              <Box mt={1}>
                <Box>
                  <Typography>
                    At 7Taste, accessible from https://www.7taste.info, one of
                    our main priorities is the privacy of our visitors. This
                    Privacy Policy document contains types of information that
                    is collected and recorded by 7Taste and how we use it.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    This Privacy Policy applies only to our online activities
                    and is valid for visitors to our website with regards to the
                    information that they shared and/or collect in 7Taste. This
                    policy is not applicable to any information collected
                    offline or via channels other than this website. Our Privacy
                    Policy was created with the help of the Privacy Policy
                    Generator.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Consent
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    By using our website, you hereby consent to our Privacy
                    Policy and agree to its terms.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Information we collect
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    The personal information that you are asked to provide, and
                    the reasons why you are asked to provide it, will be made
                    clear to you at the point we ask you to provide your
                    personal information.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    If you contact us directly, we may receive additional
                    information about you such as your name, email address,
                    phone number, the contents of the message and/or attachments
                    you may send us, and any other information you may choose to
                    provide.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    When you register for an Account, we may ask for your
                    contact information, including items such as name, company
                    name, address, email address, and telephone number.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    How we use your information
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    We use the information we collect in various ways, including
                    to:
                  </Typography>
                  <Box mt={1}>
                    <Typography>
                      ● Provide, operate, and maintain our website
                      <br />● Improve, personalize, and expand our website
                      <br />● Understand and analyze how you use our website
                      <br />● Develop new products, services, features, and
                      functionality
                      <br />● Communicate with you, either directly or through
                      one of our partners, including for customer service, to
                      provide you with updates and other information relating to
                      the website, and for marketing and promotional purposes
                      <br />● Send you emails
                      <br />● Find and prevent fraud
                    </Typography>
                  </Box>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Log Files
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    7Taste follows a standard procedure of using log files.
                    These files log visitors when they visit websites. All
                    hosting companies do this and a part of hosting services'
                    analytics. The information collected by log files include
                    internet protocol (IP) addresses, browser type, Internet
                    Service Provider (ISP), date and time stamp, referring/exit
                    pages, and possibly the number of clicks. These are not
                    linked to any information that is personally identifiable.
                    The purpose of the information is for analyzing trends,
                    administering the site, tracking users' movement on the
                    website, and gathering demographic information.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Cookies and Web Beacons
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Like any other website, 7Taste uses 'cookies'. These cookies
                    are used to store information including visitors'
                    preferences, and the pages on the website that the visitor
                    accessed or visited. The information is used to optimize the
                    users' experience by customizing our web page content based
                    on visitors' browser type and/or other information.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    For more general information on cookies, please read "What
                    Are Cookies".
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Google DoubleClick DART Cookie
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Google is one of a third-party vendor on our site. It also
                    uses cookies, known as DART cookies, to serve ads to our
                    site visitors based upon their visit to www.website.com and
                    other sites on the internet. However, visitors may choose to
                    decline the use of DART cookies by visiting the Google ad
                    and content network Privacy Policy at the following URL –
                    https://policies.google.com/technologies/ads
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Our Advertising Partners
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Some of advertisers on our site may use cookies and web
                    beacons. Our advertising partners are listed below. Each of
                    our advertising partners has their own Privacy Policy for
                    their policies on user data. For easier access, we
                    hyperlinked to their Privacy Policies below.
                  </Typography>
                  <Box mt={1}>
                    <Typography>
                      ● Google https://policies.google.com/technologies/ads
                    </Typography>
                  </Box>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Advertising Partners Privacy Policies
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    You may consult this list to find the Privacy Policy for
                    each of the advertising partners of 7Taste.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Third-party ad servers or ad networks uses technologies like
                    cookies, JavaScript, or Web Beacons that are used in their
                    respective advertisements and links that appear on 7Taste,
                    which are sent directly to users' browser. They
                    automatically receive your IP address when this occurs.
                    These technologies are used to measure the effectiveness of
                    their advertising campaigns and/or to personalize the
                    advertising content that you see on websites that you visit.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Note that 7Taste has no access to or control over these
                    cookies that are used by third-party advertisers.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Third Party Privacy Policies
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    7Taste's Privacy Policy does not apply to other advertisers
                    or websites. Thus, we are advising you to consult the
                    respective Privacy Policies of these third-party ad servers
                    for more detailed information. It may include their
                    practices and instructions about how to opt-out of certain
                    options.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    You can choose to disable cookies through your individual
                    browser options. To know more detailed information about
                    cookie management with specific web browsers, it can be
                    found at the browsers' respective websites.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    CCPA Privacy Rights
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Under the CCPA, among other rights, California consumers
                    have the right to:
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Request that a business that collects a consumer's personal
                    data disclose the categories and specific pieces of personal
                    data that a business has collected about consumers.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Request that a business that sells a consumer's personal
                    data, not sell the consumer's personal data.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    GDPR Data Protection Rights
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    We would like to make sure you are fully aware of all of
                    your data protection rights. Every user is entitled to the
                    following:
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    The right to access – You have the right to request copies
                    of your personal data. We may charge you a small fee for
                    this service.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    The right to rectification – You have the right to request
                    that we correct any information you believe is inaccurate.
                    You also have the right to request that we complete the
                    information you believe is incomplete.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    The right to erasure – You have the right to request that we
                    erase your personal data, under certain conditions.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    The right to restrict processing – You have the right to
                    request that we restrict the processing of your personal
                    data, under certain conditions.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    The right to object to processing – You have the right to
                    object to our processing of your personal data, under
                    certain conditions.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    The right to data portability – You have the right to
                    request that we transfer the data that we have collected to
                    another organization, or directly to you, under certain
                    conditions.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Children's Information
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Another part of our priority is adding protection for
                    children while using the internet. We encourage parents and
                    guardians to observe, participate in, and/or monitor and
                    guide their online activity.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    7Taste does not knowingly collect any Personal Identifiable
                    Information from children under the age of 13. If you think
                    that your child provided this kind of information on our
                    website, we strongly encourage you to contact us immediately
                    and we will do our best efforts to promptly remove such
                    information from our records.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={1} flex="3"></Box>
          </Stack>
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
          >
            <MobileFooter />
          </Box>
        </Container>
      </Box>
      <Box sx={displayOnDesktop}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Privacy;
