import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MobileFooter from "../../components/MobileFooter";
import ReactRouterLink from "../../components/ReactRouterLink";
import { displayOnDesktop } from "../../themes/CommonStyles";
import ReactGA from "react-ga4";

const AboutUs = () => {
  ReactGA.send(window.location.pathname);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>About Us | 7Taste</title>
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="7taste" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Box>
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: 100,
          overflowY: "scroll",
        }}
      >
        <Container maxWidth="xl" sx={{ mb: 3 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box mt={1} flex="1"></Box>
            <Box mt={1} flex="8">
              <Box mt={2}>
                <Typography variant="h1" fontSize={24}>
                  About 7taste
                </Typography>
              </Box>
              <Box mt={1}>
                <Box>
                  <Typography>
                    7taste was founded as one place to find the very best of
                    what’s interesting on the web, bringing together latest and
                    healty recipes.
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="h2" fontWeight="bold">
                    Privacy
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    You can read our Privacy Notice{" "}
                    <ReactRouterLink to="/privacy">here</ReactRouterLink>.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Terms
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    You can read our Terms and Condition{" "}
                    <ReactRouterLink to="/termsofservice">here</ReactRouterLink>
                    .
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="h2" fontWeight="bold">
                    Contact
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography>
                    Send questions or comments{" "}
                    <ReactRouterLink to="/contactus">here</ReactRouterLink>.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={1} flex="3"></Box>
          </Stack>
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
          >
            <MobileFooter />
          </Box>
        </Container>
      </Box>
      <Box sx={displayOnDesktop}>
        <Footer />
      </Box>
    </Box>
  );
};

export default AboutUs;
