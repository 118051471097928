import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  StepContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import MobileFooter from "../../components/MobileFooter";
import { carouselImage, displayOnDesktop } from "../../themes/CommonStyles";
import { useParams } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import CustomizedRating from "../../components/CustomizedRating";
import { allRecipes } from "../../data/recipes";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate, useLocation } from "react-router-dom";
import WebShare from "../../components/WebShare";

const RecipeDetails = () => {
  let { navParam } = useParams();
  const navigate = useNavigate();

  const [selReceipe, setSelReceipe] = useState({});

  const { pathname } = useLocation();

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    console.log(pathname);
  }, [pathname]);

  useEffect(() => {
    const fRec = allRecipes.filter((rec) => rec.nav === navParam);
    setSelReceipe(fRec[0]);
    return () => {};
  }, [navParam]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const simplify = (numerator, denominator) => {
    var fraction = {
      numerator: numerator,
      denominator: denominator,
    };

    for (var i = fraction.denominator; i > 0; i--) {
      if (fraction.denominator % i === 0 && fraction.numerator % i === 0) {
        fraction.numerator /= i;
        fraction.denominator /= i;
      }
    }

    return fraction;
  };

  const convertDecimalToFraction = (decimal) => {
    var frac;
    if (decimal === null) return null;
    if (decimal % 1 > 0) {
      var ans = simplify(decimal.toString().substring(2), Math.pow(10, 2));
      frac = ans.numerator + "/" + ans.denominator;
    }
    var num = Math.floor(decimal);
    if (num > 0 && frac) {
      return num + " \xa0" + frac;
    } else if (num > 0) {
      return num;
    } else if (frac) {
      return frac;
    }
  };

  if (!selReceipe) return;

  ReactGA.send(window.location.pathname);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>{`${selReceipe.metaTitle}`}</title>
        <meta
          name="description"
          content={`Step by Step recipe of ${selReceipe.title}`}
        />
        <meta name="keywords" content={`${selReceipe.metaKeywords}`} />
        <meta property="og:title" content={selReceipe.metaTitle} />
        <meta
          property="og:description"
          content={`Step by Step recipe of ${selReceipe.title}`}
        />
        <meta property="og:image" content={`${selReceipe.ogImg}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="7taste" />
        <meta property="og:url" content={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify(selReceipe.structuredData)}
        </script>
      </Helmet>
      <Box>
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: 100,
          overflowY: "scroll",
        }}
      >
        <Container maxWidth="xl" sx={{ mb: 3 }}>
          <Box sx={{ mx: 2, mt: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={1}>
              <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box mt={1} mb={2}>
                  <Typography variant="h1" fontSize={40} fontWeight="bolder">
                    {selReceipe.title}
                  </Typography>
                </Box>

                <Box mt={1}>
                  <Box
                    component="img"
                    src={selReceipe.imgUrl}
                    alt={selReceipe.title}
                    maxWidth="100%"
                    onClick={() => {}}
                  ></Box>
                </Box>
                <Box mt={2}>
                  <Typography fontWeight="bolder">
                    Serves: {selReceipe.serves}
                  </Typography>
                </Box>
                {selReceipe.prepTime && (
                  <Box mt={2}>
                    <Typography fontWeight="bolder">
                      Preparation: {selReceipe.prepTime} min
                    </Typography>
                  </Box>
                )}
                {selReceipe.cookTime && (
                  <Box mt={2}>
                    <Typography fontWeight="bolder">
                      Cooking: {selReceipe.cookTime} min
                    </Typography>
                  </Box>
                )}
                {selReceipe.totalTime && (
                  <Box mt={2}>
                    <Typography fontWeight="bolder">
                      Total: {selReceipe.totalTime} min
                    </Typography>
                  </Box>
                )}
                {/* <Box>
                  <Typography fontSize={18} fontWeight="bolder" mt={2}>
                    Rating
                  </Typography>
                </Box>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper elevation={1}>
                      <Box>
                        <CustomizedRating
                          ratingText="Direction"
                          ratingVal={2.5}
                          isReadOnly={true}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper elevation={1}>
                      <Box>
                        <CustomizedRating
                          ratingText="Cinematography"
                          ratingVal={3.5}
                          isReadOnly={true}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper elevation={1}>
                      <Box>
                        <CustomizedRating
                          ratingText="Music"
                          ratingVal={3}
                          isReadOnly={true}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                </Grid> */}
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      variant="h2"
                      fontSize={24}
                      fontWeight="bolder"
                      mt={4}
                      mb={2}
                    >
                      Ingredients
                    </Typography>
                    <ol
                      style={{
                        listStyleType: "disc",
                        paddingInlineStart: "30px",
                      }}
                    >
                      {selReceipe.ingredient
                        ? selReceipe.ingredient.map((ingr) => {
                            return (
                              <li key={ingr.name}>
                                <Typography>
                                  {convertDecimalToFraction(ingr.qty)}
                                  {" \xa0"}
                                  {ingr.name}
                                </Typography>
                              </li>
                            );
                          })
                        : ""}
                    </ol>
                  </Grid>
                </Grid>
                <Box sx={{ maxWidth: "100%" }}>
                  <Typography
                    variant="h2"
                    fontSize={24}
                    fontWeight="bolder"
                    mt={4}
                    mb={2}
                  >
                    {selReceipe.stepsTitle}
                  </Typography>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {selReceipe.steps &&
                      selReceipe.steps.map((stp, index) => (
                        <Step
                          key={stp.step}
                          sx={{
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: "green",
                            },
                          }}
                        >
                          <StepLabel>
                            <Typography variant="caption" fontWeight="bold">
                              {stp.label}
                            </Typography>
                          </StepLabel>
                          <StepContent>
                            <Typography>{stp.desc}</Typography>
                            <Box sx={{ mb: 2 }}>
                              <div>
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {selReceipe.steps &&
                                  index === selReceipe.steps.length - 1
                                    ? "Finish"
                                    : "Next"}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Back
                                </Button>
                              </div>
                            </Box>
                          </StepContent>
                        </Step>
                      ))}
                  </Stepper>
                  {selReceipe.steps && activeStep === selReceipe.steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                      <Typography>
                        All steps completed - Ready to Serve
                      </Typography>
                      <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                      </Button>
                    </Paper>
                  )}
                </Box>
                <Box>
                  <WebShare
                    text={selReceipe.metaTitle}
                    url={window.location.href}
                    title="Share"
                  />
                  {/* <WhatsAppShare sendText={`/recipe/${selReceipe.nav}`} /> */}
                </Box>
                <Box>
                  <Typography
                    variant="h2"
                    fontSize={24}
                    fontWeight="bolder"
                    mt={4}
                    mb={0}
                  >
                    Related Recipes
                  </Typography>
                  <Grid container rowSpacing={1} columnSpacing={3}>
                    {selReceipe.relatedRecipes &&
                      selReceipe.relatedRecipes.map((relRecipe, index) => {
                        return (
                          <Grid key={index} item xs={12} sm={4} md={4} lg={4}>
                            <Box
                              className="carouselCard"
                              sx={{
                                flexGrow: 1,
                                position: "relative",
                              }}
                            >
                              <Box sx={{ cursor: "pointer" }}>
                                <Link href={`/recipe/${relRecipe.nav}`}>
                                  <Box
                                    component="img"
                                    sx={carouselImage}
                                    src={
                                      relRecipe.imgUrl ? relRecipe.imgUrl : null
                                    }
                                    alt={relRecipe.title}
                                  ></Box>
                                  <Typography
                                    variant="h2"
                                    fontSize={20}
                                    fontWeight="bolder"
                                  >
                                    {relRecipe.title}
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={0} sm={0} md={4} lg={4}></Grid>
            </Grid>
          </Box>
          <Box>
            <MobileFooter />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default RecipeDetails;
