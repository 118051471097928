export const homeItemList = {
  "@context": "https://schema.org",
  "@type": "ItemList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      url: "https://www.7taste.info/recipe/hal-obbattu",
    },
    {
      "@type": "ListItem",
      position: 2,
      url: "https://www.7taste.info/recipe/lychee-kiwi-fruit-salad",
    },
    {
      "@type": "ListItem",
      position: 3,
      url: "https://www.7taste.info/recipe/coconut-ragi-kheer",
    },
  ],
};

export const sweetItemList = {
  "@context": "https://schema.org",
  "@type": "ItemList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      url: "https://www.7taste.info/recipe/hal-obbattu",
    },
    {
      "@type": "ListItem",
      position: 2,
      url: "https://www.7taste.info/recipe/coconut-ragi-kheer",
    },
    {
      "@type": "ListItem",
      position: 3,
      url: "https://www.7taste.info/recipe/halbai",
    },
  ],
};
