import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import FeedIcon from "@mui/icons-material/Feed";
import MovieIcon from "@mui/icons-material/Movie";

const SideNavbar = ({ openDrawer, setOpenDrawer }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/")}>
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <FeedIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
};

export default SideNavbar;
