import { Box, Button } from "@mui/material";
import React from "react";
import { RWebShare } from "react-web-share";
import OfflineShareIcon from "@mui/icons-material/OfflineShare";

const WebShare = ({ text, url, title }) => {
  const cars = ["facebook", "whatsapp", "copy"];
  return (
    <Box mt={3}>
      <RWebShare
        data={{
          text: text,
          url: url,
          title: title,
        }}
        sites={cars}
        disableNative="true"
        onClick={() => console.log("shared successfully!")}
      >
        <Button
          variant="outlined"
          startIcon={<OfflineShareIcon />}
          sx={{ fontSize: 20, fontWeight: "bolder" }}
        >
          Share this recipe
        </Button>
      </RWebShare>
    </Box>
  );
};

export default WebShare;
