import { Box, Container, Link, Paper, Stack } from "@mui/material";
import React from "react";
import { flexBetweenCenter, fullWidthFlex } from "../themes/CommonStyles";
import ReactRouterLink from "./ReactRouterLink";

const Footer = () => {
  return (
    <Box
      sx={{
        ...fullWidthFlex,
        borderTop: "1px solid #ddd",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            ...flexBetweenCenter,
            width: "100%",
          }}
        >
          <Stack>
            <Paper>
              <Link href="#"> 2022 Copyright</Link>
            </Paper>
            <Paper>
              <ReactRouterLink to="/privacy">Privacy</ReactRouterLink>
            </Paper>
            <Paper>
              <ReactRouterLink to="/aboutus">About Us</ReactRouterLink>
            </Paper>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
