import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import axiosConfig from "../../axiosConfig";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MobileFooter from "../../components/MobileFooter";
import { displayOnDesktop } from "../../themes/CommonStyles";
import ReactGA from "react-ga4";

const ContactUs = () => {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    desc: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      axiosConfig({
        url: "/news/contactus",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: formValues,
      });
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
    ) {
      errors.email = "Invalid Email";
    }

    if (!values.desc) {
      errors.desc = "Description is required";
    }
    return errors;
  };
  ReactGA.send(window.location.pathname);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>Contact Us | 7Taste</title>
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="7taste" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Box>
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: 100,
          overflowY: "scroll",
        }}
      >
        <Container maxWidth="lg" sx={{ mb: 3 }}>
          <Box mt={1}>
            <Typography fontSize={24} variant="h1">
              Contact Us
            </Typography>
          </Box>
          <Box mt={2}>
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Typography>Name</Typography>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  name="name"
                  required
                  value={formValues.name}
                  onChange={handleChange}
                  sx={{ width: "80%" }}
                />
                {formErrors.name ? (
                  <Alert severity="error" sx={{ width: "60%", marginLeft: 4 }}>
                    {formErrors.name}
                  </Alert>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Typography>Email</Typography>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  name="email"
                  required
                  value={formValues.email}
                  onChange={handleChange}
                  sx={{ width: "80%" }}
                />
                {formErrors.email ? (
                  <Alert severity="error" sx={{ width: "60%", marginLeft: 4 }}>
                    {formErrors.email}
                  </Alert>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Typography>Description</Typography>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  multiline
                  rows="6"
                  name="desc"
                  required
                  value={formValues.desc}
                  onChange={handleChange}
                  sx={{ width: "80%" }}
                />
                {formErrors.desc ? (
                  <Alert severity="error" sx={{ width: "60%", marginLeft: 4 }}>
                    {formErrors.desc}
                  </Alert>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
          >
            <MobileFooter />
          </Box>
        </Container>
      </Box>
      <Box sx={displayOnDesktop}>
        <Footer />
      </Box>
    </Box>
  );
};

export default ContactUs;
