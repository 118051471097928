import { Box, Button, Link, Paper, Stack } from "@mui/material";
import React from "react";
import { justifyCenter } from "../themes/CommonStyles";
import ReactRouterLink from "./ReactRouterLink";

const MobileFooter = () => {
  return (
    <Box sx={{ borderTop: "1px solid #ccc", mt: 3, pt: 2, width: "100%" }}>
      <Paper>
        <Button>
          <Box
            sx={{
              ...justifyCenter,
              mr: 1,
            }}
          >
            <Stack>
              <Paper>
                <Link href="#"> 2022 Copyright</Link>
              </Paper>
              <Paper>
                <ReactRouterLink to="/privacy">Privacy</ReactRouterLink>
              </Paper>
              <Paper>
                <ReactRouterLink to="/termsofservice">
                  Term of Service
                </ReactRouterLink>
              </Paper>
              <Paper>
                <ReactRouterLink to="/aboutus">About Us</ReactRouterLink>
              </Paper>
              {/* <Paper>
                            <Link href='#'> Sitemap</Link>
                        </Paper> */}
            </Stack>
          </Box>
        </Button>
      </Paper>
    </Box>
  );
};

export default MobileFooter;
