import {
  Box,
  Container,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MobileFooter from "../../components/MobileFooter";
import { displayOnDesktop } from "../../themes/CommonStyles";
import ReactGA from "react-ga4";
import HomeCards from "../../components/home/HomeCards";
import { latestRecipes } from "../../data/recipes";
import { homeMenu } from "../../data/menu-data";
import { homeItemList } from "../../data/item-list";

const Home = () => {
  ReactGA.send(window.location.pathname);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>Recipes | 7Taste</title>
        <meta
          name="description"
          content="7taste strives to provide food recipes that are extremely easy to make."
        />
        <meta
          name="keywords"
          content="7taste, recipes, cooking recipes, healthy recipes, vegetarian recipes, indian recipes, festival recipes, easy recipes"
        />
        <meta property="og:title" content="Recipes | 7Taste" />
        <meta
          property="og:description"
          content="7taste strives to provide food recipes that are extremely easy to make."
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="7Taste" />
        <meta property="og:url" content={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify(homeItemList)}
        </script>
      </Helmet>
      <Box>
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: 100,
          overflowY: "scroll",
        }}
      >
        <Container maxWidth="xl" sx={{ mb: 3 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box mt={1} flex="1"></Box>
            <Box mt={1} flex="8">
              <Box mt={1} mb={2}>
                <Typography variant="h1" fontSize={24} fontWeight="bold" ml={4}>
                  Recipes
                </Typography>
              </Box>

              <HomeCards recipes={latestRecipes} />
              {/* <Box>
                <ImageList
                  sx={{ width: 500, height: 450 }}
                  cols={3}
                  rowHeight={164}
                >
                  {homeMenu.map((menu) => (
                    <ImageListItem key={menu.id}>
                      <img
                        src={`${menu.imgUrl}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${menu.imgUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={menu.label}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box> */}
            </Box>
            <Box mt={1} flex="3"></Box>
          </Stack>
          <Box>
            <MobileFooter />
          </Box>
        </Container>
      </Box>
      {/* <Box sx={displayOnDesktop}>
        <Footer />
      </Box> */}
    </Box>
  );
};

export default Home;
