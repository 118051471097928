import "./App.css";
import React from "react";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/common/Home";
import RecipeDetails from "./pages/recipe/RecipeDetails";
import Privacy from "./pages/common/Privacy";
import Login from "./pages/login/Login";
import ReactGA from "react-ga4";
import ContactUs from "./pages/common/ContactUs";
import TermsOfService from "./pages/common/TermsOfService";
import AboutUs from "./pages/common/AboutUs";
import SaladFeed from "./pages/salad/SaladFeed";
import SoupFeed from "./pages/soup/SoupFeed";
import SweetFeed from "./pages/sweet/SweetFeed";
import RiceFeed from "./pages/rice/RiceFeed";
import BreadFeed from "./pages/bread/BreadFeed";

function App() {
  ReactGA.initialize("G-53R38EBHKN");
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/recipe/:navParam" element={<RecipeDetails />} />
          <Route exact path="/salad" element={<SaladFeed />} />
          <Route exact path="/soup" element={<SoupFeed />} />
          <Route exact path="/sweet-recipes" element={<SweetFeed />} />
          <Route exact path="/rice" element={<RiceFeed />} />
          <Route exact path="/bread" element={<BreadFeed />} />
          <Route exact path="/admin/login" element={<Login />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route exact path="/termsofservice" element={<TermsOfService />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
