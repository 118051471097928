export const latestRecipes = [
  {
    id: 1,
    title: "Obbattu | Bele Obbattu | Holige",
    nav: "bele-obbattu",
    imgUrl: "/images/bele-obbattu.jpg",
    category: "sweet",
  },
  {
    id: 2,
    title: "Jackfruit Seed Halwa",
    nav: "jackfruit-seed-halwa",
    imgUrl: "/images/jackfruit-seeds-halwa.jpg",
    category: "sweet",
  },
  {
    id: 3,
    title: "Hal Obbattu | Halu Holige",
    nav: "hal-obbattu",
    imgUrl: "/images/hal-obbattu.jpg?auto=format&fit=crop&w=387&q=80",
    category: "sweet",
  },
  {
    id: 4,
    title: "Lychee Kiwi Fruit Salad",
    nav: "lychee-kiwi-fruit-salad",
    imgUrl: "/images/lychee-kiwi-salad.jpg?auto=format&fit=crop&w=387&q=80",
    category: "salad",
  },
  {
    id: 5,
    title: "Coconut Ragi (Millet) Kheer",
    nav: "coconut-ragi-kheer",
    imgUrl: "/images/coconut-ragi-kheer.jpg?auto=format&fit=crop&w=387&q=80",
    category: "sweet",
  },
  {
    id: 6,
    title: "Black Raisins Juice",
    nav: "black-raisins-juice",
    imgUrl: "/images/black-raisins-juice.jpg?auto=format&fit=crop&w=387&q=80",
    category: "beverages",
  },
  {
    id: 7,
    title: "Halbai",
    nav: "halbai",
    imgUrl: "/images/halbai.jpg?auto=format&fit=crop&w=387&q=80",
    category: "sweet",
  },
  {
    id: 8,
    title: "Eggless Brownie",
    nav: "eggless-brownie",
    imgUrl:
      "https://images.unsplash.com/photo-1461009312844-e80697a81cc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=788&q=80",
    category: "dessert",
  },
  {
    id: 9,
    title: "Vegan Pancake",
    nav: "vegan-pancake",
    imgUrl: "https://images.pexels.com/photos/65170/pexels-photo-65170.jpeg",
    category: "pancake",
  },
  {
    id: 10,
    title: "Banana Bread",
    nav: "banana-bread",
    imgUrl:
      "https://images.unsplash.com/photo-1569762404472-026308ba6b64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    category: "bread",
  },
  {
    id: 11,
    title: "Orange Butter Fruit Salad",
    nav: "orange-butter-fruit-salad",
    imgUrl:
      "https://images.unsplash.com/photo-1618153340460-e6636c3c5c15?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fG9yYW5nZSUyMHNhbGFkfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    category: "salad",
  },
  {
    id: 12,
    title: "Cabbage Apple Soup",
    nav: "cabbage-apple-soup",
    imgUrl:
      "https://images.unsplash.com/photo-1616501268013-9a0ed08d2cbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    category: "soup",
  },
];

export const allRecipes = [
  {
    id: 1,
    title: "Green Leaves Salad",
    nav: "green-leaves-salad",
    imgUrl:
      "https://images.unsplash.com/photo-1547496502-affa22d38842?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8c2FsYWR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    category: "salad",
    ingredient: [
      { qty: "0.50", name: "romaine lettuce" },
      { qty: "0.50", name: "curly endive" },
      { qty: "1.50", name: "(cup) lamb's lettuce" },
      { qty: null, name: "arugula or rocket" },
      { qty: null, name: "watercress" },
      { qty: null, name: "red radicchio or chard" },
      { qty: "2.50", name: "flat-leaf parsley sprigs" },
      { qty: "0.25", name: "(cup) olive oil" },
      { qty: "2", name: "tablespoons balsamic vinegar" },
      { qty: null, name: "salt and black pepper" },
    ],
    stepsTitle: "Step-by-Step Green Leaves Salad Recipe",
    steps: [
      {
        step: "1",
        label: "Separate the leaves",
        desc: "Separate the leaves of the endive and romaine lettuce and discard the outer bitter leaves of the endive.",
      },
      {
        step: "2",
        label: "Tear the greens",
        desc: "Tear the salad greens into manageable lengths.",
      },
      {
        step: "3",
        label: "Combine all greens",
        desc: "Combine all the greens in a bowl and toss well.",
      },
      {
        step: "4",
        label: "Whisk the oil",
        desc: "Whisk the oil, vinegar, salt, and pepper in a small bowl.",
      },
      {
        step: "5",
        label: "Drizzle over the salad",
        desc: "Drizzle over the salad and toss well.",
      },
      {
        step: "6",
        label: "Drizzle with oil",
        desc: "Drizzle with a little more oil and add an extra grinding of pepper, if liked.",
      },
    ],
    serves: 6,
    prepTime: 10,
    totalTime: 10,
    metaKeywords: "Green Leaves Salad recipe, salad recipe",
    metaTitle: "Green Leaves Salad Recipe | 7Taste",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Green Leaves Salad",
      image: [
        "https://images.unsplash.com/photo-1547496502-affa22d38842?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8c2FsYWR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      ],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-08-28",
      description: "Step by Step recipe of Green Leaves Salad.",
      prepTime: "PT10M",
      cookTime: "PT10M",
      totalTime: "PT20M",
      keywords: "Green Leaves Salad recipe, salad recipe",
      recipeYield: "6",
      recipeCategory: "Salad",
      recipeCuisine: "American",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "270 calories",
      },
      recipeIngredient: [
        "1/2 romaine lettuce",
        "1/2 curly endive",
        "1 1/2 (cup) lamb's lettuce",
        "arugula or rocket",
        "watercress",
        "red radicchio or chard",
        "2 1/2 flat-leaf parsley sprigs",
        "1/4 (cup) olive oil",
        "2 tablespoons balsamic vinegar",
        "salt and black pepper",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Separate the leaves",
          text: "Separate the leaves of the endive and romaine lettuce and discard the outer bitter leaves of the endive.",
        },
        {
          "@type": "HowToStep",
          name: "Tear the greens",
          text: "Tear the salad greens into manageable lengths.",
        },
        {
          "@type": "HowToStep",
          name: "Combine all greens",
          text: "Combine all the greens in a bowl and toss well.",
        },
        {
          "@type": "HowToStep",
          name: "Whisk the oil",
          text: "Whisk the oil, vinegar, salt, and pepper in a small bowl.",
        },
        {
          "@type": "HowToStep",
          name: "Bake",
          text: "Bake for 30 to 35 minutes, or until firm.",
          image: "https://example.com/photos/party-coffee-cake/step5.jpg",
        },
        {
          "@type": "HowToStep",
          name: "Drizzle over the salad",
          text: "Drizzle over the salad and toss well.",
        },
        {
          "@type": "HowToStep",
          name: "Drizzle with oil",
          text: "Drizzle with a little more oil and add an extra grinding of pepper, if liked.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Cabbage and Apple Soup",
        nav: "cabbage-apple-soup",
        imgUrl:
          "https://images.unsplash.com/photo-1616501268013-9a0ed08d2cbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      },
    ],
  },
  {
    id: 2,
    title: "Orange Butter Fruit Salad",
    nav: "orange-butter-fruit-salad",
    imgUrl:
      "https://images.unsplash.com/photo-1618153340460-e6636c3c5c15?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fG9yYW5nZSUyMHNhbGFkfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    category: "salad",
    ingredient: [
      { qty: "2", name: "oranges" },
      { qty: "8", name: "spinach leaves" },
      { qty: "2", name: "butter fruit (peeled, pitted, and sliced)" },
      { qty: null, name: "lemon juice" },
      { qty: "0.25", name: "olive oil" },
      { qty: "2", name: "spring onions (trimmed and sliced)" },
      { qty: null, name: "grated zest of 1 orange" },
      { qty: null, name: "salt" },
      { qty: null, name: "black pepper" },
    ],
    stepsTitle: "Step-by-Step Orange and Butter Fruit Salad Recipe",
    steps: [
      {
        step: "1",
        label: "Peel the oranges",
        desc: "Peel the oranges using a knife, remove the bitter white pith.",
      },
      {
        step: "2",
        label: "Break the fruit",
        desc: "Break the fruit into segments.",
      },
      {
        step: "3",
        label: "Spinach leaves",
        desc: "Put the spinach leaves in a large salad bowl.",
      },
      {
        step: "4",
        label: "Top with fruits",
        desc: "Top with the oranges and butter fruit.",
      },
      {
        step: "5",
        label: "Beat",
        desc: "Beat the lemon juice and oil in a small bowl with a fork.",
      },
      {
        step: "6",
        label: "Orange zest",
        desc: "Add the scallions and oranges zest and season with salt and pepper. Beat well.",
      },
      {
        step: "7",
        label: "Toss",
        desc: "Drizzle the dressing over the salad and toss carefully.",
      },
    ],
    serves: 4,
    prepTime: 15,
    totalTime: 15,
    metaKeywords: "Orange Butter fruit Salad recipe, salad recipe",
    metaTitle: "Orange Butter Fruit Salad Recipe | 7Taste",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Orange and Butter fruit Salad",
      image: [
        "https://images.unsplash.com/photo-1618153340460-e6636c3c5c15?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fG9yYW5nZSUyMHNhbGFkfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      ],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-08-28",
      description: "Step by Step recipe of Orange and Butter fruit Salad.",
      prepTime: "PT15M",
      cookTime: "PT5M",
      totalTime: "PT15M",
      keywords: "Orange and Butter fruit Salad recipe, salad recipe",
      recipeYield: "4",
      recipeCategory: "Salad",
      recipeCuisine: "American",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "270 calories",
      },
      recipeIngredient: [
        "2 oranges",
        "8 spinach leaves",
        "2 butter fruit (peeled, pitted, and sliced)",
        "lemon juice",
        "1/4 olive oil",
        "2 spring onions (trimmed and sliced)",
        "grated zest of 1 orange",
        "salt",
        "black pepper",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Peel the oranges",
          text: "Peel the oranges using a knife, remove the bitter white pith.",
        },
        {
          "@type": "HowToStep",
          name: "Break the fruit",
          text: "Break the fruit into segments.",
        },
        {
          "@type": "HowToStep",
          name: "Spinach leaves",
          text: "Put the spinach leaves in a large salad bowl.",
        },
        {
          "@type": "HowToStep",
          name: "Top with fruits",
          text: "Top with the oranges and butter fruit.",
        },
        {
          "@type": "HowToStep",
          name: "Beat",
          text: "Beat the lemon juice and oil in a small bowl with a fork.",
          image: "https://example.com/photos/party-coffee-cake/step5.jpg",
        },
        {
          "@type": "HowToStep",
          name: "Orange zest",
          text: "Drizzle the dressing over the salad and toss carefully.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Green Leaves Salad",
        nav: "green-leaves-salad",
        imgUrl:
          "https://images.unsplash.com/photo-1547496502-affa22d38842?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8c2FsYWR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      },
    ],
  },
  {
    id: 3,
    title: "Cabbage Apple Soup",
    nav: "cabbage-apple-soup",
    imgUrl:
      "https://images.unsplash.com/photo-1616501268013-9a0ed08d2cbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    category: "soup",
    ingredient: [
      { qty: "2", name: "tablespoons currants" },
      { qty: "2", name: "tablespoons sunflower oil" },
      { qty: "1", name: "onion (cut in thin rings)" },
      { qty: "2", name: "apples (peeled, cored, and finely chopped)" },
      { qty: "1", name: "cabbage (cored and finely shredded)" },
      { qty: "0.50", name: "teaspoon dried thyme salt" },
      { qty: "2.25", name: "cups vegetable broth" },
      { qty: "0.50", name: "teaspoon caraway seeds ground" },
      { qty: null, name: "pinch of nutmeg" },
      { qty: "1", name: "tablespoon lemon juice" },
      { qty: null, name: "black pepper ground" },
      { qty: "0.25", name: "cup heavy cream" },
      { qty: "1", name: "tablespoon coarse-grain mustard" },
      { qty: "1", name: "tablespoon finely chopped parsley" },
    ],
    stepsTitle: "Step-by-Step Cabbage Apple Soup Recipe",
    steps: [
      {
        step: "1",
        label: "Soak currants",
        desc: "Put the currants in a small bowl, cover with hot water, and leave to soak for 10 minutes. Drain and set aside.",
      },
      {
        step: "2",
        label: "Deep frying",
        desc: "Heat the oil in a medium soup pot or deep frying pan over medium-low heat.",
      },
      {
        step: "3",
        label: "Add the onion",
        desc: "Add the onion and saute for 3 minutes.",
      },
      {
        step: "4",
        label: "Add the apple, cabbage, thyme, and salt",
        desc: "Add the apple, cabbage, thyme, and salt. Stir thoroughly and saute for 5 more minutes.",
      },
      {
        step: "5",
        label: "Pour in the broth",
        desc: "Pour in the broth, cover, and simmeruntil the cabbage is tender but still retains a little bite, 6-8minutes.",
      },
      {
        step: "6",
        label: "Stir",
        desc: "Stir in the caraway seeds, nutmeg, and lemon juice, and season with salt and pepper.",
      },
      {
        step: "7",
        label: "Remove the soup",
        desc: "Remove the soup from the heat. Whip the cream until stiff and mix with the mustard, pinch of salt, and ground pepper.",
      },
      {
        step: "8",
        label: "Fold the cream",
        desc: "Fold the cream into the soup. Ladle into small bowls and serve immediately, sprinkled with parsley and currants.",
      },
    ],
    serves: 4,
    prep: 15,
    cooking: 15,
    metaKeywords: "Cabbage Apple Soup recipe, soup recipe",
    metaTitle: "Cabbage Apple Soup Recipe | 7Taste",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Cabbage Apple Soup",
      image: [
        "https://images.unsplash.com/photo-1616501268013-9a0ed08d2cbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      ],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-08-28",
      description: "Step by Step recipe of Cabbage and Apple Soup.",
      prepTime: "PT15M",
      cookTime: "PT15M",
      totalTime: "PT30M",
      keywords: "Cabbage and Apple Soup recipe, soup recipe",
      recipeYield: "4",
      recipeCategory: "Soup",
      recipeCuisine: "American",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "270 calories",
      },
      recipeIngredient: [
        "2 tablespoons currants",
        "2 tablespoons sunflower oil",
        "1 onion (cut in thin rings)",
        "2 apples (peeled, cored, and finely chopped)",
        "1 cabbage (cored and finely shredded)",
        "1/2 teaspoon dried thyme salt",
        "2 1/4 cups vegetable broth",
        "1/2 teaspoon caraway seeds ground",
        "pinch of nutmeg",
        "1 tablespoon lemon juice",
        "black pepper ground",
        "1/4 cup heavy cream",
        "1 tablespoon coarse-grain mustard",
        "1 tablespoon finely chopped parsley",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Soak currants",
          text: "Put the currants in a small bowl, cover with hot water, and leave to soak for 10 minutes. Drain and set aside.",
        },
        {
          "@type": "HowToStep",
          name: "Deep frying",
          text: "Heat the oil in a medium soup pot or deep frying pan over medium-low heat.",
        },
        {
          "@type": "HowToStep",
          name: "Add the onion",
          text: "Add the onion and saute for 3 minutes.",
        },
        {
          "@type": "HowToStep",
          name: "Add the apple, cabbage, thyme, and salt",
          text: "Add the apple, cabbage, thyme, and salt. Stir thoroughly and saute for 5 more minutes.",
        },
        {
          "@type": "HowToStep",
          name: "Pour in the broth",
          text: "Pour in the broth, cover, and simmeruntil the cabbage is tender but still retains a little bite, 6-8minutes.",
          image: "https://example.com/photos/party-coffee-cake/step5.jpg",
        },
        {
          "@type": "HowToStep",
          name: "Stir",
          text: "Stir in the caraway seeds, nutmeg, and lemon juice, and season with salt and pepper.",
        },
        {
          "@type": "HowToStep",
          name: "Remove the soup",
          text: "Remove the soup from the heat. Whip the cream until stiff and mix with the mustard, pinch of salt, and ground pepper.",
        },
        {
          "@type": "HowToStep",
          name: "Fold the cream",
          text: "Fold the cream into the soup. Ladle into small bowls and serve immediately, sprinkled with parsley and currants.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Halbai",
        nav: "halbai",
        imgUrl: "/images/halbai.jpg?auto=format&fit=crop&w=387&q=80",
      },
    ],
  },
  {
    id: 4,
    title: "Banana Bread",
    nav: "banana-bread",
    imgUrl:
      "https://images.unsplash.com/photo-1569762404472-026308ba6b64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    category: "bread",
    ingredient: [
      { qty: "2", name: "cups all-purpose flour" },
      { qty: "1", name: "teaspoon baking soda" },
      { qty: "2", name: "teaspoon flaxseed" },
      { qty: "0.50", name: "cup almond milk" },
      { qty: "0.75", name: "cup brown sugar" },
      { qty: "2.50", name: "ripe bananas, peeled" },
      { qty: "0.50", name: "cup sunflower oil" },
      { qty: "1", name: "teaspoon vanilla extract" },
      { qty: null, name: "salt" },
    ],
    stepsTitle: "Step-by-Step Banana Bread Recipe",
    steps: [
      {
        step: "1",
        label: "Preheat",
        desc: "Preheat oven to 180 degrees Celsius (350 degrees Fahrenheit) for 20 minutes.",
      },
      {
        step: "2",
        label: "Combine the flaxseed",
        desc: "Combine the flaxseed with almond milk in a mixing bowl.",
      },
      {
        step: "3",
        label: "Mash bananas",
        desc: "In a separate bowl, mash the ripe bananas with a fork.",
      },
      {
        step: "4",
        label: "Batter",
        desc: "Stir the oil into the mashed bananas. Add the sugar, flaxseed(combined with almond milk in step 2) and vanilla extract. Stir in the flour, baking soda and salt.",
      },
      {
        step: "5",
        label: "Bake the bread",
        desc: "Pour the batter into greased or lined loaf pan. Bake for 40 minutes (until toothpick inserted in the bread comes out clean).",
      },
      {
        step: "6",
        label: "Remove the bread from the oven",
        desc: "Remove the bread from the oven and let it cool in the pan for a 10 minutes. Then remove the banana bread from pan and place it on a wired rack and let it cool completely.",
      },
    ],
    serves: 6,
    prepTime: 10,
    cookTime: 50,
    totalTime: 60,
    metaKeywords:
      "Banana Bread recipe, eggless Banana Bread recipe, bread recipe",
    metaTitle: "Banana Bread Recipe | 7Taste",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Banana Bread",
      image: [
        "https://images.unsplash.com/photo-1569762404472-026308ba6b64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      ],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-08-28",
      description: "Step by Step recipe of Banana Bread.",
      prepTime: "PT10M",
      cookTime: "PT50M",
      totalTime: "PT60M",
      keywords:
        "Banana Bread recipe, eggless Banana Bread recipe, bread recipe",
      recipeYield: "1 Loaf",
      recipeCategory: "Bread",
      recipeCuisine: "American",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "230 calories",
      },
      recipeIngredient: [
        "2 cups all-purpose flour",
        "1 teaspoon baking soda",
        "2 teaspoon flaxseed",
        "1/2 cup almond milk",
        "1/3 cup brown sugar",
        "2 1/2 ripe bananas, peeled",
        "1/2 cup sunflower oil",
        "1 teaspoon vanilla extract",
        "salt",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Preheat",
          text: "Preheat oven to 180 degrees Celsius (350 degrees Fahrenheit) for 20 minutes.",
        },
        {
          "@type": "HowToStep",
          name: "Combine the flaxseed",
          text: "Combine the flaxseed with almond milk in a mixing bowl.",
        },
        {
          "@type": "HowToStep",
          name: "Mash bananas",
          text: "In a separate bowl, mash the ripe bananas with a fork.",
        },
        {
          "@type": "HowToStep",
          name: "Batter",
          text: "Stir the oil into the mashed bananas. Add the sugar, flaxseed(combined with almond milk in step 2) and vanilla extract. Stir in the flour, baking soda and salt.",
        },
        {
          "@type": "HowToStep",
          name: "Bake the bread",
          text: "Pour the batter into greased or lined loaf pan. Bake for 40 minutes (until toothpick inserted in the bread comes out clean).",
        },
        {
          "@type": "HowToStep",
          name: "Remove the bread from the oven",
          text: "Remove the bread from the oven and let it cool in the pan for a 10 minutes. Then remove the banana bread from pan and place it on a wired rack and let it cool completely.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Halbai",
        nav: "halbai",
        imgUrl: "/images/halbai.jpg?auto=format&fit=crop&w=387&q=80",
      },
    ],
  },
  {
    id: 5,
    title: "Vegan Pancake",
    nav: "vegan-pancake",
    imgUrl: "https://images.pexels.com/photos/65170/pexels-photo-65170.jpeg",
    category: "pancake",
    ingredient: [
      { qty: "1.25", name: "cups all-purpose flour" },
      { qty: "1", name: "teaspoon baking soda" },
      { qty: "1", name: "teaspoon sugar" },
      { qty: "1", name: "teaspoon vanilla extract" },
      { qty: "1", name: "cup almond or soya milk" },
      { qty: "1", name: "teaspoon sunflower oil" },
      { qty: null, name: "salt" },
      { qty: null, name: "chocolate chips" },
    ],
    stepsTitle: "Step-by-Step Vegan Pancake Recipe",
    steps: [
      {
        step: "1",
        label: "Flour mixture",
        desc: "Add the flour, sugar, baking soda, and salt in a mixing bowl and stir to combine.",
      },
      {
        step: "2",
        label: "Stir milk with vanilla",
        desc: "In a separate bowl, add almond milk or soya milk and vanilla extract, and stir to combine.",
      },
      {
        step: "3",
        label: "Batter",
        desc: "Pour into flour mixture(prepared in step 1), whisk until you get a smooth batter. Let batter rest for 5 minutes.",
      },
      {
        step: "4",
        label: "Medium heat",
        desc: "Pour the batter(large spoonful) into little oiled onto a non-stick pan over medium-high heat.",
      },
      {
        step: "5",
        label: "Cook",
        desc: "Cook for 3 minutes until the top begins to bubble and edges are set. Flip the pancake and cook for 2 minutes until golden browned. Serve with chocolate chips toppings.",
      },
    ],
    serves: 4,
    prepTime: 5,
    cookTime: 20,
    totalTime: 25,
    metaKeywords: "vegan pancake recipe, pancake recipe, easy pancake recipe",
    metaTitle: "Vegan Pancake Recipe | 7Taste",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Vegan Pancake",
      image: ["https://images.pexels.com/photos/65170/pexels-photo-65170.jpeg"],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-08-31",
      description: "Step by Step recipe of Vegan Pancake.",
      prepTime: "PT5M",
      cookTime: "PT20M",
      totalTime: "PT25M",
      keywords: "vegan pancake recipe, pancake recipe, easy pancake recipe",
      recipeYield: "10 pancakes",
      recipeCategory: "Pancake",
      recipeCuisine: "American",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "260 calories",
      },
      recipeIngredient: [
        "1 1/4 cups all-purpose flour",
        "1 teaspoon baking soda",
        "1 teaspoon sugar",
        "1 teaspoon vanilla extract",
        "1 cup almond or soya milk",
        "1 teaspoon sunflower oil",
        "salt",
        "chocolate chips",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Flour mixture",
          text: "Add the flour, sugar, baking soda, and salt in a mixing bowl and stir to combine.",
        },
        {
          "@type": "HowToStep",
          name: "Stir milk with vanilla",
          text: "In a separate bowl, add almond milk or soya milk and vanilla extract, and stir to combine.",
        },
        {
          "@type": "HowToStep",
          name: "Batter",
          text: "Pour into flour mixture(prepared in step 1), whisk until you get a smooth batter. Let batter rest for 5 minutes.",
        },
        {
          "@type": "HowToStep",
          name: "Medium heat",
          text: "Pour the batter(large spoonful) into little oiled onto a non-stick pan over medium-high heat.",
        },
        {
          "@type": "HowToStep",
          name: "Cook",
          text: "Cook for 3 minutes until the top begins to bubble and edges are set. Flip the pancake and cook for 2 minutes until golden browned. Serve with chocolate chips toppings.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Halbai",
        nav: "halbai",
        imgUrl: "/images/halbai.jpg?auto=format&fit=crop&w=387&q=80",
      },
    ],
  },
  {
    id: 6,
    title: "Eggless Brownie",
    nav: "eggless-brownie",
    imgUrl:
      "https://images.unsplash.com/photo-1461009312844-e80697a81cc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=788&q=80",
    category: "dessert",
    ingredient: [
      { qty: "0.75", name: "cup all-purpose flour" },
      { qty: "1.50", name: "teaspoons baking powder" },
      { qty: "1", name: "cup sugar" },
      { qty: "0.25", name: "cup cocoa powder" },
      { qty: "0.50", name: "cup butter" },
      { qty: "0.50", name: "cup curd (plain yogurt) or water." },
      { qty: "1", name: "teaspoon vanilla extract" },
      { qty: "0.5", name: "cup chocolate chips or chopped walnuts" },
      { qty: null, name: "salt" },
    ],
    stepsTitle: "Step-by-Step Eggless Brownie Recipe",
    steps: [
      {
        step: "1",
        label: "Preheat",
        desc: "Preheat oven to 180 degrees Celsius (350 degrees Fahrenheit) for 10 minutes.",
      },
      {
        step: "2",
        label: "Melt butter",
        desc: "Melt butter for 20 seconds. Let it cool to the room temperature.",
      },
      {
        step: "3",
        label: "Grease the pan",
        desc: "Lightly grease the pan with butter.",
      },
      {
        step: "4",
        label: "Flour mixture",
        desc: "Take a large bowl, combine 3/4 cup all purpose flour, 1 cup sugar and 1 1/2 teaspoons baking powder. Add cocoa powder (sieve sift) and mix well.",
      },
      {
        step: "5",
        label: "Batter",
        desc: "In a seperate bowl, pour melted butter and add curd (yogurt). Add 1 teaspoon vanilla extract and mix well.Add flour mixture (prepared in step 4), stir until well blended. Add chocolate chips or chopped walnuts and mix well.",
      },
      {
        step: "6",
        label: "Bake",
        desc: "Spread evenly in the greased pan. Bake for 25-30 minutes in the preheated oven, until toothpick inserted into the center comes out clean.",
      },
      {
        step: "7",
        label: "Cool",
        desc: "Remove it from the oven and let it cool for a 10 minutes. Then remove it from pan and place it on a wired rack and let it cool completely. Slice the brownies into 9 or 12 squares.",
      },
    ],
    serves: 12,
    prepTime: 20,
    cookTime: 30,
    totalTime: 50,
    metaKeywords: "eggless brownie recipe, brownie without egg recipe",
    metaTitle: "Eggless Brownie Recipe | 7Taste",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Eggless Brownie",
      image: [
        "https://images.unsplash.com/photo-1461009312844-e80697a81cc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=788&q=80",
      ],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-09-03",
      description: "Step by Step recipe of Eggless Brownie.",
      prepTime: "PT20M",
      cookTime: "PT30M",
      totalTime: "PT50M",
      keywords: "eggless brownie recipe, brownie without egg recipe",
      recipeYield: "12 brownies",
      recipeCategory: "Dessert",
      recipeCuisine: "American",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "200 calories",
      },
      recipeIngredient: [
        "1/3 cup all-purpose flour ",
        "1 1/2 teaspoons baking powder",
        "1 cup sugar",
        "1/4 cup cocoa powder",
        "1/2 cup butter",
        "1/2 cup curd (plain yogurt) or water",
        "1 teaspoon vanilla extract",
        "1/2 cup chocolate chips or chopped walnuts",
        "salt",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Preheat",
          text: "Preheat oven to 180 degrees Celsius (350 degrees Fahrenheit) for 10 minutes.",
        },
        {
          "@type": "HowToStep",
          name: "Melt butter",
          text: "Melt butter for 20 seconds. Let it cool to the room temperature.",
        },
        {
          "@type": "HowToStep",
          name: "Grease the pan",
          text: "Lightly grease the pan with butter.",
        },
        {
          "@type": "HowToStep",
          name: "Flour mixture",
          text: "Take a large bowl, combine 3/4 cup all purpose flour, 1 cup sugar and 1 1/2 teaspoons baking powder. Add cocoa powder (sieve sift) and mix well.",
        },
        {
          "@type": "HowToStep",
          name: "Batter",
          text: "In a seperate bowl, pour melted butter and add curd (yogurt). Add 1 teaspoon vanilla extract and mix well.Add flour mixture (prepared in step 4), stir until well blended. Add chocolate chips or chopped walnuts and mix well.",
        },
        {
          "@type": "HowToStep",
          name: "Bake",
          text: "Spread evenly in the greased pan. Bake for 25-30 minutes in the preheated oven, until s toothpick inserted into the center comes out clean.",
        },
        {
          "@type": "HowToStep",
          name: "Cool",
          text: "Remove it from the oven and let it cool for a 10 minutes. Then remove it from pan and place it on a wired rack and let it cool completely. Slice the brownies into 9 or 12 squares.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Halbai",
        nav: "halbai",
        imgUrl: "/images/halbai.jpg?auto=format&fit=crop&w=387&q=80",
      },
    ],
  },
  {
    id: 7,
    title: "Halbai",
    nav: "halbai",
    imgUrl: "/images/halbai.jpg",
    category: "sweet",
    ingredient: [
      { qty: "1", name: "cup rice (soak 1 cup rice in water for 2 hours)" },
      { qty: "1", name: "cup coconut" },
      { qty: "3", name: "cup water" },
      { qty: "1", name: "cup jaggery" },
      { qty: "2", name: "teaspoon ghee" },
      { qty: "0.75", name: "teaspoon cardamom powder" },
    ],
    stepsTitle: "Step-by-Step Halbai Sweet Recipe",
    steps: [
      {
        step: "1",
        label: "Grind",
        desc: "Add soaked rice, 1 cup coconut and 1/4 cup water into a blender and grind them together to a fine paste.",
      },
      {
        step: "2",
        label: "Dissolve jaggery",
        desc: "Take a large pan, add 1 cup jaggery and 2 3/4 cup water. Stir well until jaggery dissolves completely.",
      },
      {
        step: "3",
        label: "Pour rice-coconut paste",
        desc: "Pour the rice-coconut paste (prepared in step 1) to the pan and stir until the mixture is well combined without forming lumps.",
      },
      {
        step: "4",
        label: "Cook",
        desc: "Add ghee and cook for 15 minutes till the mixture is glossy and thick. Add cardamom powder and mix well.",
      },
      {
        step: "5",
        label: "Cool and garnish",
        desc: "Spread evenly in the greased pan and let it cool completely. Slice the Halbai into squares and garnish with cashew.",
      },
    ],
    serves: 20,
    prepTime: 10,
    cookTime: 20,
    totalTime: 30,
    metaKeywords: "halbai recipe, rice halbai recipe",
    metaTitle: "Halbai Recipe | 7Taste",
    ogImg: "https://www.7taste.info/images/halbai.jpg",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Halbai",
      image: [
        "https://www.7taste.info/images/1x1/halbai.jpg",
        "https://www.7taste.info/images/4x3/halbai.jpg",
        "https://www.7taste.info/images/16x9/halbai.jpg",
      ],
      author: {
        name: "7Taste",
      },
      datePublished: "2022-09-09",
      description: "Step by Step recipe of Halbai.",
      prepTime: "PT10M",
      cookTime: "PT20M",
      totalTime: "PT30M",
      keywords: "halbai recipe, rice halbai recipe",
      recipeYield: "20 halbai",
      recipeCategory: "Sweet",
      recipeCuisine: "Indian",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "100 calories",
      },
      recipeIngredient: [
        "1 cup rice (soak 1 cup rice in water for 2 hours)",
        "1 cup coconut",
        "3 cup water",
        "1 cup jaggery",
        "2 teaspoon ghee",
        "1/4 teaspoon cardamom powder",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Grind",
          text: "Add soaked rice, 1 cup coconut and 1/4 cup water into a blender and grind them together to a fine paste.",
        },
        {
          "@type": "HowToStep",
          name: "Dissolve jaggery",
          text: "Take a large pan, add 1 cup jaggery and 2 3/4 cup water. Stir well until jaggery dissolves completely.",
        },
        {
          "@type": "HowToStep",
          name: "Pour rice-coconut paste",
          text: "Pour the rice-coconut paste (prepared in step 1) to the pan and stir until the mixture is well combined without forming lumps.",
        },
        {
          "@type": "HowToStep",
          name: "Cook",
          text: "Add ghee and cook for 15 minutes till the mixture is glossy and thick. Add cardamom powder and mix well.",
        },
        {
          "@type": "HowToStep",
          name: "Cool and garnish",
          text: "Spread evenly in the greased pan and let it cool completely. Slice the Halbai into squares and garnish with cashew.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Coconut Ragi (Millet) Kheer",
        nav: "coconut-ragi-kheer",
        imgUrl: "/images/coconut-ragi-kheer.jpg",
        category: "sweet",
      },
    ],
  },
  {
    id: 8,
    title: "Black Raisins Juice",
    nav: "black-raisins-juice",
    imgUrl: "https://www.7taste.info/images/black-raisins-juice.jpg",
    category: "beverages",
    ingredient: [
      { qty: "0.50", name: "cup raisins (soaked in water for 4 to 5 hours)" },
      { qty: "1.50", name: "teaspoon Honey" },
    ],
    stepsTitle: "Step-by-Step Black Raisins Juice Recipe",
    steps: [
      {
        step: "1",
        label: "Grind",
        desc: "Add soaked raisins and 1/2 cup water into the blender and grind them finely.",
      },
      {
        step: "2",
        label: "Strain",
        desc: "Place a strainer (sieve) over a bowl and pour the grinded juice over it. Press the pulp gently using a spatula. Discard the solid pulp.",
      },
      {
        step: "3",
        label: "Honey",
        desc: "Add 1 1/2 teaspoons of honey to the juice and stir well.",
      },
    ],
    serves: 4,
    prepTime: 5,
    totalTime: 5,
    metaKeywords: "black raisins juice recipe, raisins juice recipe",
    metaTitle: "Black Raisins Juice Recipe | 7Taste",
    ogImg:
      "https://www.7taste.info/images/black-raisins-juice.jpg?auto=format&fit=crop&w=387&q=80",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Black Raisins Juice",
      image: ["https://www.7taste.info/images/black-raisins-juice.jpg"],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-09-10",
      description: "Step by Step recipe of Black Raisins Juice.",
      prepTime: "PT05M",
      cookTime: "PT00M",
      totalTime: "PT05M",
      keywords: "black raisins juice recipe, raisins juice recipe",
      recipeYield: "4",
      recipeCategory: "Beverages",
      recipeCuisine: "Indian",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "150 calories",
      },
      recipeIngredient: [
        "1/2 cup raisins (soaked in water for 4 to 5 hours)",
        "1 1/2 teaspoon Honey",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Grind",
          text: "Add soaked raisins and 1/2 cup water into the blender and grind them finely.",
        },
        {
          "@type": "HowToStep",
          name: "Strain",
          text: "Place a strainer (sieve) over a bowl and pour the grinded juice over it. Press the pulp gently using a spatula. Discard the solid pulp.",
        },
        {
          "@type": "HowToStep",
          name: "Honey",
          text: "Add 1 1/2 teaspoons of honey to the juice and stir well.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Halbai",
        nav: "halbai",
        imgUrl: "/images/halbai.jpg?auto=format&fit=crop&w=387&q=80",
      },
    ],
  },
  {
    id: 9,
    title: "Lychee Kiwi Fruit Salad",
    nav: "lychee-kiwi-fruit-salad",
    imgUrl: "/images/lychee-kiwi-salad.jpg",
    category: "salad",
    ingredient: [
      { qty: "1", name: "cup lychee fruit" },
      { qty: "1", name: "cup kiwi fruit" },
      { qty: "2", name: "bananas" },
      { qty: "1.50", name: "teaspoons honey" },
      { qty: "0.75", name: "teaspoon cardamon powder" },
    ],
    stepsTitle: "Step-by-Step Lychee Kiwi Fruit Salad Recipe",
    steps: [
      {
        step: "1",
        label: "Peel the fruits",
        desc: "Peel the lychee, kiwi & banana fruits. Cut the lychee, kiwi and banana into small pieces.",
      },
      {
        step: "2",
        label: "Combine fruits",
        desc: "Combine all the fruits in a bowl and toss well.",
      },
      {
        step: "3",
        label: "Honey and Cardamom",
        desc: "Add 1 1/2 teaspoons of honey and 3/4 teaspoon of cardamon powder to the fruits bowl and mix well.",
      },
    ],
    serves: 4,
    prepTime: 5,
    totalTime: 5,
    metaKeywords: "lychee kiwi salad recipe, lychee kiwi fruit salad recipe",
    metaTitle: "Lychee Kiwi Fruit Salad Recipe | 7Taste",
    ogImg: "https://www.7taste.info/images/lychee-kiwi-salad.jpg",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Lychee Kiwi Fruit Salad",
      image: ["https://www.7taste.info/images/lychee-kiwi-salad.jpg"],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-09-16",
      description: "Step by Step recipe of Lychee Kiwi Fruit Salad.",
      prepTime: "PT05M",
      cookTime: "PT00M",
      totalTime: "PT05M",
      keywords: "lychee kiwi salad recipe, lychee kiwi fruit salad recipe",
      recipeYield: "4",
      recipeCategory: "Salad",
      recipeCuisine: "American",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "100 calories",
      },
      recipeIngredient: [
        "1 cup lychee fruit",
        "1 cup kiwi fruit",
        "2 bananas",
        "1 1/2 teaspoons honey",
        "3/4 teaspoon cardamon powder",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Peel the fruits",
          text: "Peel the lychee, kiwi & banana fruits. Cut the lychee, kiwi and banana into small pieces.",
        },
        {
          "@type": "HowToStep",
          name: "Combine fruits",
          text: "Combine all the fruits in a bowl and toss well.",
        },
        {
          "@type": "HowToStep",
          name: "Honey and Cardamom",
          text: "Add 1 1/2 teaspoons of honey and 3/4 teaspoon of cardamon powder to the fruits bowl and mix well.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Orange and Butter fruit Salad",
        nav: "orange-butter-fruit-salad",
        imgUrl:
          "https://images.unsplash.com/photo-1618153340460-e6636c3c5c15?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fG9yYW5nZSUyMHNhbGFkfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        category: "salad",
      },
    ],
  },
  {
    id: 10,
    title: "Coconut Ragi (Millet) Kheer",
    nav: "coconut-ragi-kheer",
    imgUrl: "/images/coconut-ragi-kheer.jpg",
    category: "sweet",
    ingredient: [
      { qty: "2", name: "cups ragi (soaked in water for 8 hours)" },
      { qty: "1", name: "cup grated coconut" },
      { qty: "0.25", name: "cup soaked almonds" },
      { qty: "1", name: "tablespoon raisins" },
      { qty: "1", name: "tablespoon cashew nuts" },
      { qty: "0.75", name: "tablespoon Cardamom powder" },
      { qty: "1", name: "cup jaggery" },
      { qty: "1", name: "tablespoon ghee" },
    ],
    stepsTitle: "Step-by-Step Coconut Ragi (Millet) Kheer Recipe",
    steps: [
      {
        step: "1",
        label: "Grind ragi",
        desc: "Add soaked ragi, and 1/4 cup water into a blender and grind them 1-2 times together to a fine milk.",
      },
      {
        step: "2",
        label: "Strain and extract ragi milk",
        desc: "Place a strainer (sieve) over a bowl and pour the grinded ragi over it. Press the pulp gently using a spatula. Discard the solid pulp. Transfer the milk to a pan.",
      },
      {
        step: "3",
        label: "Grind grated coconut and soaked almonds",
        desc: "Add grated coconut with soaked almonds, and 1/4 cup water into a blender and grind them 1-2 times together to get fine milk.",
      },
      {
        step: "4",
        label: "Strain and extract coconut almonds milk",
        desc: "Place a strainer (sieve) over a bowl and pour the grinded coconut with almonds over it. Press the pulp gently using a spatula. Discard the solid pulp. Transfer the extracted milk to the same pan used in step 2.",
      },
      {
        step: "4",
        label: "Dissolve jaggery",
        desc: "Take a large pan, add 1 cup jaggery and 2 3/4 cup water. Stir well until jaggery dissolves completely. Transfer it to the same pan used in step 2.",
      },
      {
        step: "4",
        label: "Cook",
        desc: "Simmer on low flame and boil the kheer for 10mins. Add cardamom powder and mix well.",
      },
      {
        step: "4",
        label: "Garnish",
        desc: "Roast the cashew and raisins in ghee and add it to the boiled Kheer.",
      },
    ],
    serves: 6,
    prepTime: 5,
    cookTime: 15,
    totalTime: 20,
    metaKeywords: "coconut ragi kheer recipe, coconut ragi payasam recipe",
    metaTitle: "Coconut Ragi (Millet) Kheer Recipe | 7Taste",
    ogImg: "https://www.7taste.info/images/coconut-ragi-kheer.jpg",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Coconut Ragi (Millet) Kheer",
      image: ["https://www.7taste.info/images/coconut-ragi-kheer.jpg"],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-09-16",
      description: "Step by Step recipe of Coconut Ragi (Millet) Kheer.",
      prepTime: "PT05M",
      cookTime: "PT15M",
      totalTime: "PT20M",
      keywords: "coconut ragi kheer recipe",
      recipeYield: "6",
      recipeCategory: "Sweet",
      recipeCuisine: "Indian",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "100 calories",
      },
      recipeIngredient: [
        "2 cups ragi (soaked in water for 8 hours)",
        "1 cup grated coconut",
        "1/4 cup soaked almonds",
        "1 tablespoon raisins",
        "1 tablespoon cashew nuts",
        "3/4 tablespoon Cardamom powder",
        "1 cup jaggery",
        "1 tablespoon ghee",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Grind ragi",
          text: "Add soaked ragi, and 1/4 cup water into a blender and grind them 1-2 times together to a fine milk.",
        },
        {
          "@type": "HowToStep",
          name: "Strain and extract ragi milk",
          text: "Place a strainer (sieve) over a bowl and pour the grinded ragi over it. Press the pulp gently using a spatula. Discard the solid pulp. Transfer the milk to a pan.",
        },
        {
          "@type": "HowToStep",
          name: "Grind grated coconut and soaked almonds",
          text: "Add grated coconut with soaked almonds, and 1/4 cup water into a blender and grind them 1-2 times together to get fine milk.",
        },
        {
          "@type": "HowToStep",
          name: "Strain and extract coconut almonds milk",
          text: "Place a strainer (sieve) over a bowl and pour the grinded coconut with almonds over it. Press the pulp gently using a spatula. Discard the solid pulp. Transfer the extracted milk to the same pan used in step 2.",
        },
        {
          "@type": "HowToStep",
          name: "Dissolve jaggery",
          text: "Take a large pan, add 1 cup jaggery and 2 3/4 cup water. Stir well until jaggery dissolves completely. Transfer it to the same pan used in step 2.",
        },
        {
          "@type": "HowToStep",
          name: "Cook",
          text: "Simmer on low flame and boil the kheer for 10mins. Add cardamom powder and mix well.",
        },
        {
          "@type": "HowToStep",
          name: "Garnish",
          text: "Roast the cashew and raisins in ghee and add it to the boiled Kheer.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Hal Obbattu | Halu Holige",
        nav: "hal-obbattu",
        imgUrl: "/images/hal-obbattu.jpg",
        category: "sweet",
      },
    ],
  },
  {
    id: 11,
    title: "Hal Obbattu | Halu Holige | Paal Poli",
    nav: "hal-obbattu",
    imgUrl: "/images/hal-obbattu.jpg",
    category: "sweet",
    ingredient: [
      { qty: "1", name: "litre Milk" },
      { qty: "0.50", name: "cup cashew nuts (soaked in water for 2 hours)" },
      {
        qty: "0.50",
        name: "cup almonds (soaked in hot water for 2 hours and peeled)",
      },
      { qty: "4", name: "cardamom" },
      { qty: "0.50", name: "japatre" },
      { qty: null, name: "pinch of saffron (soaked in water)" },
      { qty: "2", name: "teaspoons poppy seeds" },
      { qty: "1", name: "cup grated coconut" },
      { qty: "1", name: "cup maida or all purpose flour" },
      { qty: "0.50", name: "cup chiroti rava (semolina)" },
      { qty: "1", name: "cup sugar" },
      { qty: null, name: "salt" },
      { qty: "1", name: "teaspoon ghee" },
      { qty: null, name: "Oil for deep fry" },
    ],
    stepsTitle: "Step-by-Step Hal Obbattu Recipe",
    steps: [
      {
        step: "1",
        label: "Flour mixture",
        desc: "Take a large bowl, combine 1 cup maida flour, 1/2 cup chiroti rava (semolina), and salt. Add water as required and knead to smooth and stiff dough. Add 1 teaspoon of oil to the dough and let it rest for 10 mins.",
      },
      {
        step: "2",
        label: "Fry the poppy seeds",
        desc: "Add gasagase (poppy seeds) in a frying pan and roast until light brown. Let it cool for 10 mins.",
      },
      {
        step: "3",
        label: "Grind",
        desc: "Transfer poppy seeds into a blender. Also add in grated coconut, cardamom, japatre, peeled badam and cashew nuts. Grind until smooth paste by adding required water. Transfer the ground paste into a pan.",
      },
      {
        step: "4",
        label: "Prepare Halu",
        desc: "Pour milk to the pan (used in step 3), stir and boil the milk well. Add sugar and pinch of saffron (kesari). Boil until the milk thickens by stirring occasionally. Halu or payasa or kheer is ready. Keep it aside.",
      },
      {
        step: "5",
        label: "Prepare Poori",
        desc: "Make gooseberry sized balls (from the dough prepared in step 1) and roll it into a very thin poori. Make it as thin as possible or else it will fluff up while deep frying.",
      },
      {
        step: "6",
        label: "Deep fry",
        desc: "Deep fry the rolled pooris. Keep flipping the pooris and fry them under low flame until it turns light brown. Transfer it to a serving plate.",
      },
      {
        step: "7",
        label: "Garnish",
        desc: "Take 2 - 3 pooris in a plate and pour 2 - 3 laddle full of halu or payasa over it. Enjoy Hal Obbattu by garnishing with chopped pista and chopped almonds.",
      },
    ],
    serves: 10,
    prepTime: 10,
    cookTime: 30,
    totalTime: 40,
    metaKeywords: "hal obbattu recipe, halu holige recipe, paal poli recipe",
    metaTitle: "Hal Obbattu (Halu Holige) Recipe | 7Taste",
    ogImg: "https://www.7taste.info/images/hal-obbattu.jpg",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Hal Obbattu (Halu Holige)",
      image: [
        "https://www.7taste.info/images/1x1/hal-obbattu.jpg",
        "https://www.7taste.info/images/4x3/hal-obbattu.jpg",
        "https://www.7taste.info/images/16x9/hal-obbattu.jpg",
      ],
      author: {
        "@type": "Person",
        name: "7Taste",
      },
      datePublished: "2022-09-24",
      description:
        "Step by Step recipe of Hal Obbattu (Halu Holige) Recipe | Paal Poli Recipe.",
      prepTime: "PT10M",
      cookTime: "PT30M",
      totalTime: "PT40M",
      keywords: "hal obbattu recipe, halu holige recipe, paal poli recipe",
      recipeYield: "8",
      recipeCategory: "Sweet",
      recipeCuisine: "Indian",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "100 calories",
      },
      recipeIngredient: [
        "1 litre Milk",
        "1/2 cup cashew nuts (soaked in water for 2 hours)",
        "1/2 cup almonds (soaked in hot water for 2 hours and peeled)",
        "4 cardamom",
        "1/2 japatre",
        "pinch of saffron (soaked in water)",
        "2 teaspoons poppy seeds",
        "1 cup grated coconut",
        "1 cup maida or all purpose flour",
        "1/2 cup chiroti rava (semolina)",
        "1 cup sugar",
        "salt",
        "1 teaspoon ghee",
        "Oil for deep fry",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Flour mixture",
          text: "Take a large bowl, combine 1 cup maida flour, 1/2 cup chiroti rava (semolina), and salt. Add water as required and knead to smooth and stiff dough. Add 1 teaspoon of oil to the dough and let it rest for 10 mins.",
        },
        {
          "@type": "HowToStep",
          name: "Fry the poppy seeds",
          text: "Add gasagase (poppy seeds) in a frying pan and roast until light brown. Let it cool for 10 mins.",
        },
        {
          "@type": "HowToStep",
          name: "Grind",
          text: "Transfer poppy seeds into a blender. Also add in grated coconut, cardamom, japatre, peeled badam and cashew nuts. Grind until smooth paste by adding required water. Transfer the ground paste into a pan.",
        },
        {
          "@type": "HowToStep",
          name: "Prepare Halu",
          text: "Pour milk to the pan (used in step 3), stir and boil the milk well. Add sugar and pinch of saffron (kesari). Boil until the milk thickens by stirring occasionally. Halu or payasa or kheer is ready. Keep it aside.",
        },
        {
          "@type": "HowToStep",
          name: "Prepare Poori",
          text: "Make gooseberry sized balls (from the dough prepared in step 1) and roll it into a very thin poori. Make it as thin as possible or else it will fluff up while deep frying.",
        },
        {
          "@type": "HowToStep",
          name: "Deep fry",
          text: "Deep fry the rolled pooris. Keep flipping the pooris and fry them under low flame until it turns light brown. Transfer it to a serving plate.",
        },
        {
          "@type": "HowToStep",
          name: "Garnish",
          text: "Take 2 - 3 pooris in a plate and pour 2 - 3 laddle full of halu or payasa over it. Enjoy Hal Obbattu by garnishing with chopped pista and chopped almonds.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Halbai",
        nav: "halbai",
        imgUrl: "/images/halbai.jpg",
        category: "sweet",
      },
    ],
  },
  {
    id: 12,
    title: "Jackfruit Seed Halwa",
    nav: "jackfruit-seed-halwa",
    imgUrl: "/images/jackfruit-seeds-halwa.jpg",
    category: "sweet",
    ingredient: [
      { qty: "1", name: "cup jackfruit seeds (washed and sun-dried)" },
      { qty: "1", name: "cup grated coconut" },
      {
        qty: "1",
        name: "cup jaggery",
      },
      { qty: "4", name: "teaspoon ghee" },
      { qty: "1", name: "teaspoon cardamon powder" },
      { qty: "0.25", name: "cup cashew nuts" },
      { qty: "0.25", name: "cup almonds" },
      { qty: "0.25", name: "cup raisins" },
    ],
    stepsTitle: "Step-by-Step Jackfruit Seed Halwa Recipe",
    steps: [
      {
        step: "1",
        label: "Jackfruit seeds",
        desc: "Cut jackfruit seeds into halves and pressure cook jackfruit seeds in enough water for 3 whistles till tender. Let the pressure subside naturally.",
      },
      {
        step: "2",
        label: "Grind",
        desc: "Transfer the jackfruit seeds to a mixer grinder and grind until smooth paste without using water.",
      },
      {
        step: "3",
        label: "Cook",
        desc: "Add 3 teaspoons of ghee to a non stick pan, add the jackfruit seeds paste, jaggery, grated coconut and cardamom powder, and cook till the mixture thickens.",
      },
      {
        step: "4",
        label: "Garnish",
        desc: "Roast the cashew, almonds and raisins in ghee (1 teaspoon ghee) and add it to the mixture.",
      },
    ],
    serves: 10,
    prepTime: 10,
    cookTime: 20,
    totalTime: 30,
    metaKeywords: "jackfruit seed halwa recipe, jackfruit seed sweet recipe",
    metaTitle: "Jackfruit Seed Halwa Recipe | 7Taste",
    ogImg: "https://www.7taste.info/images/jackfruit-seeds-halwa.jpg",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Jackfruit Seed Halwa",
      image: [
        "https://www.7taste.info/images/1x1/jackfruit-seeds-halwa.jpg",
        "https://www.7taste.info/images/4x3/jackfruit-seeds-halwa.jpg",
        "https://www.7taste.info/images/16x9/jackfruit-seeds-halwa.jpg",
      ],
      author: {
        name: "7Taste",
      },
      datePublished: "2022-09-30",
      description: "Step by Step recipe of Jackfruit Seed Halwa Recipe.",
      prepTime: "PT10M",
      cookTime: "PT20M",
      totalTime: "PT30M",
      keywords: "jackfruit seed halwa recipe, jackfruit seed sweet recipe",
      recipeYield: "10",
      recipeCategory: "Sweet",
      recipeCuisine: "Indian",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "100 calories",
      },
      recipeIngredient: [
        "1 cup jackfruit seeds (washed and sun-dried)",
        "1 cup grated coconut",
        "1 cup jaggery",
        "4 teaspoon ghee",
        "1 teaspoon cardamon powder",
        "1/4 cup cashew nuts",
        "1/4 cup almonds",
        "1/4 cup raisins",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Jackfruit seeds",
          text: "Cut jackfruit seeds into halves and pressure cook jackfruit seeds in enough water for 3 whistles till tender. Let the pressure subside naturally.",
        },
        {
          "@type": "HowToStep",
          name: "Grind",
          text: "Transfer the jackfruit seeds to a mixer grinder and grind until smooth paste without using water.",
        },
        {
          "@type": "HowToStep",
          name: "Cook",
          text: "Add 3 teaspoons of ghee to a non stick pan, add the jackfruit seeds paste, jaggery, grated coconut and cardamom powder, and cook till the mixture thickens.",
        },
        {
          "@type": "HowToStep",
          name: "Garnish",
          text: "Roast the cashew, almonds and raisins in ghee (1 teaspoon ghee) and add it to the mixture.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Halbai",
        nav: "halbai",
        imgUrl: "/images/halbai.jpg",
        category: "sweet",
      },
    ],
  },
  {
    id: 13,
    title: "Obbattu | Bele Obbattu | Holige",
    nav: "bele-obbattu",
    imgUrl: "/images/bele-obbattu.jpg",
    category: "sweet",
    ingredient: [
      { qty: "2", name: "cups maida or all purpose flour" },
      { qty: "1", name: "teaspoon turmeric" },
      { qty: "0.50", name: "teaspoon salt" },
      { qty: "7", name: "cups water" },
      { qty: "0.50", name: "cup oil" },
      { qty: "2", name: "cups chana dal (soaked for 10 minutes)" },
      { qty: "2", name: "cups jaggery" },
      { qty: "2", name: "cups grated coconut" },
      { qty: "0.50", name: "teaspoon cardamom powder" },
      { qty: null, name: "ghee" },
    ],
    stepsTitle: "Step-by-Step Bele Obbattu Sweet Recipe",
    steps: [
      {
        step: "1",
        label: "Knead the dough",
        desc: "Take a large mixing bowl, put 2 cups of all-purpose flour, 1/2 teaspoon turmeric and 1/2 teaspoon salt, mix well. Add water as required and prepare soft dough. Pour 2 teaspoon of oil and let it rest for 2 hours.",
      },
      {
        step: "2",
        label: "Cook the Dal",
        desc: "Add soaked chana dal in a pressure cooker. Also add 3 cups of water and 1 teaspoon oil. Pressure cook on medium flame for 2 whistles (until dal is cooked well). Place a strainer (sieve) over a bowl and pour the cooked dal over it.",
      },
      {
        step: "3",
        label: "Prepare Stuffing",
        desc: "Transfer the cooked and drained dal into a large kadai. Add 2 cup jaggery and 2 cup grated coconut, cook the mixture on medium flame till the jaggery melts. Cook until the mixture thickens. Add 1/4 teaspoon cardamom powder and let it cool for 5 minutes. Grind the mixture into fine paste. Make sure there are no lumps.",
      },
      {
        step: "4",
        label: "Obbattu",
        desc: "Knead the dough slightly and pinch ball sized dough and flatten it. Place a lemon sized prepared stuffing (prepared in step 4) in center and cover it evenly with the dough. Press lightly and pat it with hand on a plastic sheet or banana leaf. Transfer it to hot pan carefully and roast on medium flame till bubbles appear. Flip over and cook both sides.",
      },
    ],
    serves: 18,
    prepTime: 180,
    cookTime: 60,
    totalTime: 240,
    metaKeywords:
      "obbattu recipe, bele obbattu recipe, holige recipe, puran poli recipe",
    metaTitle: "Bele Obbattu Recipe (Holige Recipe) | 7Taste",
    ogImg: "https://www.7taste.info/images/hal-obbattu.jpg",
    structuredData: {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: "Obbattu | Bele Obbattu | Holige",
      image: ["https://www.7taste.info/images/hal-obbattu.jpg"],
      author: {
        name: "7Taste",
      },
      datePublished: "2022-11-19",
      description: "Step by Step recipe of Bele Obbattu Sweet Recipe.",
      prepTime: "PT180M",
      cookTime: "PT60M",
      totalTime: "PT240M",
      keywords:
        "obbattu recipe, bele obbattu recipe, holige recipe, puran poli recipe",
      recipeYield: "18",
      recipeCategory: "Sweet",
      recipeCuisine: "Indian",
      nutrition: {
        "@type": "NutritionInformation",
        calories: "100 calories",
      },
      recipeIngredient: [
        "2 cups maida or all purpose flour",
        "1 teaspoon turmeric",
        "0.50 teaspoon salt",
        "7 cups water",
        "0.50 cup oil",
        "2 cups chana dal (soaked for 10 minutes)",
        "2 cups jaggery",
        "2 cups grated coconut",
        "0.50 teaspoon cardamom powder",
        "ghee",
      ],
      recipeInstructions: [
        {
          "@type": "HowToStep",
          name: "Knead the dough",
          text: "Take a large mixing bowl, put 2 cups of all-purpose flour, 1/2 teaspoon turmeric and 1/2 teaspoon salt, mix well. Add water as required and prepare soft dough. Pour 2 teaspoon of oil and let it rest for 2 hours.",
        },
        {
          "@type": "HowToStep",
          name: "Cook the Dal",
          text: "Add soaked chana dal in a pressure cooker. Also add 3 cups of water and 1 teaspoon oil. Pressure cook on medium flame for 2 whistles (until dal is cooked well). Place a strainer (sieve) over a bowl and pour the cooked dal over it.",
        },
        {
          "@type": "HowToStep",
          name: "Prepare Stuffing",
          text: "Transfer the cooked and drained dal into a large kadai. Add 2 cup jaggery and 2 cup grated coconut, cook the mixture on medium flame till the jaggery melts. Cook until the mixture thickens. Add 1/4 teaspoon cardamom powder and let it cool for 5 minutes. Grind the mixture into fine paste. Make sure there are no lumps.",
        },
        {
          "@type": "HowToStep",
          name: "Obbattu",
          text: "Knead the dough slightly and pinch ball sized dough and flatten it. Place a lemon sized prepared stuffing (prepared in step 4) in center and cover it evenly with the dough. Press lightly and pat it with hand on a plastic sheet or banana leaf. Transfer it to hot pan carefully and roast on medium flame till bubbles appear. Flip over and cook both sides.",
        },
      ],
    },
    relatedRecipes: [
      {
        id: 1,
        title: "Hal Obbattu | Halu Holige | Paal Poli",
        nav: "hal-obbattu",
        imgUrl: "/images/hal-obbattu.jpg",
        category: "sweet",
      },
    ],
  },
];

export const saladRecipes = [
  {
    id: 1,
    title: "Green Leaves Salad",
    nav: "green-leaves-salad",
    imgUrl:
      "https://images.unsplash.com/photo-1547496502-affa22d38842?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8c2FsYWR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    category: "salad",
  },
  {
    id: 2,
    title: "Orange and Butter fruit Salad",
    nav: "orange-butter-fruit",
    imgUrl:
      "https://images.unsplash.com/photo-1618153340460-e6636c3c5c15?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fG9yYW5nZSUyMHNhbGFkfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    category: "salad",
  },
];

export const riceRecipes = [
  {
    id: 1,
    title: "Lemon Rice",
    nav: "lemon-rice",
    imgUrl:
      "https://cdn.pixabay.com/photo/2015/09/05/23/35/paella-926303_960_720.jpg",
    category: "rice",
  },
];

export const breadRecipes = [
  {
    id: 1,
    title: "Banana Bread",
    nav: "banana-bread",
    imgUrl:
      "https://cdn.pixabay.com/photo/2015/09/05/23/35/paella-926303_960_720.jpg",
    category: "bread",
  },
];

export const sweetRecipes = [
  {
    id: 1,
    title: "Hal Obbattu | Halu Holige",
    nav: "hal-obbattu",
    imgUrl: "/images/hal-obbattu.jpg?auto=format&fit=crop&w=387&q=80",
    category: "sweet",
  },
  {
    id: 2,
    title: "Coconut Ragi (Millet) Kheer",
    nav: "coconut-ragi-kheer",
    imgUrl: "/images/coconut-ragi-kheer.jpg?auto=format&fit=crop&w=387&q=80",
    category: "sweet",
  },
  {
    id: 3,
    title: "Halbai",
    nav: "halbai",
    imgUrl: "/images/halbai.jpg?auto=format&fit=crop&w=387&q=80",
    category: "sweet",
  },
  {
    id: 4,
    title: "Obbattu | Bele Obbattu | Holige",
    nav: "bele-obbattu",
    imgUrl: "/images/bele-obbattu.jpg?auto=format&fit=crop&w=387&q=80",
    category: "sweet",
  },
];
