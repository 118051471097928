import { Box, Chip, Grid, Link, Paper, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { latestRecipes } from "../../data/recipes";
import { carouselImage } from "../../themes/CommonStyles";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const HomeCards = ({ recipes }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mx: 2 }}>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {recipes.map((recipe, index) => {
          return (
            <Grid key={index} item xs={12} sm={4} md={4} lg={4}>
              <Box
                className="carouselCard"
                sx={{
                  flexGrow: 1,
                  position: "relative",
                }}
              >
                {/* <Box sx={fixedIcon}>
            <IconButton aria-label="add to favorites">
                <Checkbox  icon={<FavoriteBorder />} checkedIcon={<Favorite sx={{color:"red"}}/>} />
            </IconButton>
        </Box>  */}
                <Box sx={{ cursor: "pointer" }}>
                  <Box
                    component="img"
                    sx={carouselImage}
                    src={recipe.imgUrl ? recipe.imgUrl : null}
                    alt={recipe.title}
                    onClick={() => navigate(`/recipe/${recipe.nav}`)}
                  ></Box>
                </Box>
                <Box>
                  <Box sx={{ mt: 1 }}>
                    <Paper className="overflow3line">
                      <Link
                        href={`/recipe/${recipe.nav}`}
                        sx={{
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/recipe/${recipe.nav}`)}
                      >
                        {recipe.title}
                      </Link>
                      <Box mt={1}>
                        <Chip
                          label={recipe.category}
                          variant="outlined"
                          sx={{ textTransform: "capitalize" }}
                        />
                      </Box>
                    </Paper>
                    {/* <Box component='span' className='overflow3line' >{article.description}
                </Box> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default HomeCards;
