import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import MobileFooter from "../../components/MobileFooter";
import ReactGA from "react-ga4";
import BreadCards from "../../components/bread/BreadCards";

const BreadFeed = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Helmet>
        <title>Bread - 7Taste</title>
        <meta
          name="description"
          content="7Taste: Read the latest news content of Entertainment, Science, Technology and Business from global regions along with Local and National News."
        />
        <meta
          name="keywords"
          content="bread recipes, bread baking, easy bread recipes"
        />
      </Helmet>
      <Box>
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: 100,
          overflowY: "scroll",
        }}
      >
        <Container maxWidth="xl" sx={{ mb: 3 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box mt={1} flex="1"></Box>
            <Box mt={1} flex="8">
              <Box mt={1} mb={2}>
                <Typography variant="h1" fontSize={24} fontWeight="bold" ml={4}>
                  Bread Recipes
                </Typography>
              </Box>

              <BreadCards />
            </Box>
            <Box mt={1} flex="3"></Box>
          </Stack>
          <Box>
            <MobileFooter />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default BreadFeed;
