import { createTheme, ThemeProvider } from "@mui/material";
import { grey, pink } from "@mui/material/colors";
import React, { useContext, useState } from "react";

const ThemeContext = React.createContext();
const ThemeUpdateContext = React.createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function useThemeUpdate() {
  return useContext(ThemeUpdateContext);
}

const AppThemeProvider = (prop) => {
  const [darkMode, setDarkMode] = useState(false);

  function toggleTheme() {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  }

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Raleway",
        textTransform: "none",
        fontSize: 18,
      },
    },
    palette: {
      primary: {
        main: grey[700],
      },
      secondary: {
        main: pink[500],
      },
      mode: darkMode ? "dark" : "light",
    },
    components: {
      MuiTypography: {
        defaultProps: {
          sx: {
            px: 1,
          },
          //variant: "subtitle2",
          //textTransform: "capitalize",
        },
      },
      MuiStack: {
        defaultProps: {
          sx: {
            px: 2,
            py: 1,
          },
          spacing: 2,
          direction: "row",
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiLink: {
        defaultProps: {
          sx: {
            color: (theme) => theme.palette.primary.main,
          },
          underline: "none",
        },
      },
      MuiButton: {
        defaultProps: {
          size: "small",
          p: 0,
          disableRipple: true,
        },
        variant: "text",
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  });

  return (
    <ThemeContext.Provider value={darkMode}>
      <ThemeUpdateContext.Provider value={toggleTheme}>
        <ThemeProvider theme={theme}> {prop.children} </ThemeProvider>
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
};

export default AppThemeProvider;
